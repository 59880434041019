.App {
  background-color: var(--black-1);
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

h1 {
  padding: 0;
  margin: 0;
}

.color-gray {
  color: var(--gray);
}

.main {
  width: 85%;
  margin: 0 auto;
  height: 100%;
  display: flex;
}

.left-menu,
.page-content {
  height: 100%;
}

.left-menu {
  flex: 2;
  display: flex;
  flex-direction: column;
  background-color: var(--black-3);
  &__header {
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--black-5);
    color: white;
  }
  &__content {
    flex: 1;
    padding: 20px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
  }
  &__footer {
    height: 50px;
    background-color: var(--black-5);
    display: flex;
    justify-content: center;
    gap: 20px;
    color: var(--gray);
    align-items: center;
    i {
      transition: all 0.3s;
      cursor: pointer;
      &:hover {
        color: var(--main);
      }
    }
  }
  &__portrait {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.page-content {
  flex: 5;
  padding: 30px;
  color: white;
  background-color: var(--black-2);
  overflow-y: scroll;
  &__header {
    height: 300px;
    padding: 20px;
    position: relative;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 1099px) {
      justify-content: center;
    }
    z-index: 1;
    h1 {
      font-size: 50px;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--black-1);
      opacity: 0.7;
      z-index: -1;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
}

.right-menu {
  width: 7%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--black-3);
  &__menu-wrapper {
    cursor: pointer;
    background-color: var(--black-5);
    padding: 30px 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    .home-icon {
      transition: all 0.3s;
    }
    &:hover .home-icon {
      color: var(--main);
    }
  }
  i {
    color: var(--gray);
    cursor: pointer;
  }
  &__contact {
    color: var(--gray);
    font-weight: 600;
    transform: rotate(90deg);
    margin-top: 85px;
    text-transform: uppercase;
    white-space: nowrap;
    a {
      color: var(--gray);
      text-decoration: none;
      transition: all 0.3s;
      &:hover {
        letter-spacing: 2px;
        color: var(--main);
      }
    }
  }
}

.box-grid {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(3, 1fr);
}

.box {
  height: 270px;
  width: 100%;
  background-color: var(--black-4);
  padding: 10px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow);
  p {
    display: -webkit-box;
    color: var(--gray);
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: auto;
  }
}

.button {
  display: inline-flex;
  align-items: center;
  padding: 10px 25px;
  font-weight: 600;
  color: var(--black-1);
  white-space: nowrap;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
  i {
    margin-right: 5px;
  }
}

.auto-type {
  margin: 20px 0;
}

.content-info {
  display: flex;
  justify-content: space-between;
  color: white;
}

.divider {
  background-color: var(--gray);
  width: 100%;
  height: 1px;
  opacity: 0.3;
  margin: 20px 0;
}

.circular-wrapper {
  display: flex;
  gap: 20px;
}

.circular-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    color: white;
  }
}

.bar-wrapper {
  p {
    color: white;
  }
  .bar {
    height: 10px;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    background-color: var(--light-gray);

    .bar-progress {
      width: 100%;
      height: 100%;
      transition: ease-in-out 1s;
    }
  }
}

.info-text {
  display: flex;
  align-items: center;
  i {
    color: var(--main);
    margin-right: 5px;
  }
  p {
    color: var(--gray);
  }
}

.project-box {
  display: flex;
  flex-direction: column;
  background-color: var(--black-4);
  //border:1px solid var(--main);
  min-height: 320px;
  padding: 10px;
  width: 100%;
  &__content {
    flex: 1;
  }
  p {
    display: -webkit-box;
    color: var(--gray);
    max-width: 100%;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: auto;
  }
  &__tools-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &__buttons {
    display: flex;
    justify-self: flex-end;
    gap: 15px;
    .button {
      flex: 1;
    }
  }
}

.chip {
  display: flex;
  align-items: center;
  background: var(--black-2);
  border-radius: 30px;
  padding: 5px 15px;
  font-size: 14px;
  p {
    padding: 0;
    margin: 0;
  }
  i {
    margin-right: 5px;
  }
}

.page-project {
  &__buttons {
    display: flex;
    gap: 20px;
  }
  p {
    //color:var(--gray);
  }
}

@media only screen and (max-width: 1450px) and (min-width: 1200px) {
  .main {
    width: 95%;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 1100px) {
  .main {
    width: 100%;
  }
}

@media only screen and (max-width: 1099px) {
  .App {
    height: auto;
  }
  .main {
    flex-direction: column;
  }
  .button {
    font-size: 15px;
    white-space: initial;
  }
  p {
    font-size: 15px;
  }
  .box-grid {
    grid-template-columns: (repeat(1, 1fr));
  }
  .right-menu {
    width: 100%;
    &__contact {
      transform: initial;
      margin-top: 20px;
    }
  }
  .page-content {
    text-align: center;
    &__header {
      background-position: center;
      text-align: center;
      color: white;
      h1 {
        font-size: 30px;
      }
    }
  }
  .page-project {
    &__buttons {
      flex-direction: column;
      .button {
        width: 90%;
        justify-content: center;
      }
    }
  }
  .box {
    text-align: center;
    .button-wrapper {
      text-align: center;
    }
  }
}
