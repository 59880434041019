:root{
  --black-1: #191923;
  --black-2: #1E1E28;
  --black-3: #20202A;
  --black-4: #2C2C36;
  --black-5: #252530;
  --main: #448deb;
  --light-main: #68a9fd;
  --gray: #8c8c8e;
  --light-gray: #d6d6d6;
  --warning: #FFC107;
  --shadow: 0 3px 8px 0 rgba(15,15,20,.3);
  --error: #dc3545;
  --error-mid: #cb3e4d; 
  --error-light: #e5606c;
}

*{
  box-sizing: border-box;
}

html{
  height:100%;
}

body {
  margin: 0;
  height:100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root{
  height:100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
