.Alert{
    background-color: var(--error-light);
    border:1px solid var(--error-mid);
    color:1px solid var(--error);
    padding: 1rem;
    color:white;
    margin-bottom: 2rem;
    display:flex;
    align-items: center;
    font-size: 1rem;
    i{
        margin-right: 1rem;
    }
}