.loadingScreen{
    display:flex;
    align-items: center;
    justify-content: center;
    width:100%;
    height: 100%;
}

.loadingWrapper{
    width:18.75rem;
}


@media only screen and (max-width:600px){
    .loadingWrapper{
        width:13.75rem;
    }

}